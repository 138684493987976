<template>
  <div class="spin" v-if="loading">
    <atom-spinner :animation-duration="1000" :size="60" color="#00569C" />
    <div class="err" v-if="error">Произошла ошибка, повторите попытку позже</div>
  </div>
  <div v-if="!loading">
    <div class="vac_sh">
      <div class="filter_sh">
        <!-- <div class="filter_sh_l">
          <h4>Выберите учреждение</h4>
          <select v-model="medorgCheck" @change="onChangeMedorg">
            <option selected v-bind:value="null">- Любая -</option>
            <option v-for="item in medorg" :key="item" v-bind:value="item.tid">
              {{ item.name }}
            </option>
          </select>
        </div> -->

        <div class="filter_sh_l">
          <h4>Выберите специальность</h4>
          <select v-model="taxCheck" @change="onChange">
            <option selected v-bind:value="null">- Любая -</option>
            <option v-for="(item, index) in term" :key="index" :value="item.tid">
              {{ item.name }}
            </option>
          </select>

          <div v-if="taxCheck != null" @change="onChangeTaxChild" class="filter_sh_child">
            <select v-model="taxChildCheck">
              <option selected v-bind:value="null">- Любая -</option>
              <option
                v-for="(item, index) in term[taxIndex].tax"
                :key="index"
                :value="item.tidSub"
              >
                {{ item.subName }}
              </option>
            </select>
          </div>
        </div>

        <div class="filter_sh_r">
          <h4></h4>
          <button @click="resetFilters" class="btn-vac">Сбросить</button>
        </div>
      </div>

      <div class="card_box">
        <div class="cards__none" v-if="content.length <= 0">
          По заданному фильтру ничего не найдено
        </div>
        <div v-for="(post, index) in content" :key="index" class="card_sh">
          <div class="vac_block">
            <div class="vac">{{ post.taxChild }}</div>
            <div class="vac_price">{{ post.dohod }}</div>
            <div class="vac_org">
              <p v-html="post.checkOR"></p>
              <span>{{ post.sdg }}</span>
            </div>
            <!-- <div class="vac_work">
              Опыт <span>{{ post.opWork }}</span>
            </div> -->
            <a :href="post.path" target="_BLANK" class="btn-vac">Подробнее</a>
          </div>
          <div class="vac_mo_logo">
            <img class="img-responsive" :src="post.logo" />
          </div>
        </div>

        <paginate
          v-show="pageCount > 0"
          v-model="page"
          :page-count="pageCount"
          :page-range="6"
          :margin-pages="1"
          :click-handler="changePage"
          :prev-text="'Назад'"
          :next-text="'Вперед'"
          :container-class="'paginate'"
        >
        </paginate>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { AtomSpinner } from "epic-spinners";
import Paginate from "vuejs-paginate-next";

export default {
  name: "App",
  data() {
    return {
      loading: true,
      error: false,
      content: [],
      dataAPI: [],
      medorg: [],
      term: [],
      taxonomy: [],
      taxCheck: null,
      taxChildCheck: null,
      taxIndex: 0,
      medorgCheck: 79,
      page: 1,
      pageCount: 0,
      pagination_offset: 0,
      urlMO: "",
      urlTax: "",
    };
  },
  components: {
    AtomSpinner,
    Paginate,
  },
  computed: {},
  methods: {
    applyFilters() {},
    onChangeMedorg() {
      // if (this.medorgCheck == null) {
      //   this.urlMO = "field_check_or_tid_selective=All&";
      // } else {
      this.urlMO = `field_check_or_tid_selective=${this.medorgCheck}&`;
      // }
      this.page == 1;
      this.pagination_offset = 0;
      this.postCheckData();
    },
    onChange(event) {
      if (event.target.options.selectedIndex != 0) {
        this.taxIndex = Number(event.target.options.selectedIndex) - 1;
      } else {
        this.taxIndex = 0;
      }

      this.taxChildCheck = null;

      if (this.taxCheck == null) {
        this.urlTax = "shs_term_node_tid_depth=All&";
      } else {
        this.urlTax = `shs_term_node_tid_depth=${this.taxCheck}&`;
      }
      this.page == 1;
      this.pagination_offset = 0;
      this.postCheckData();
    },
    onChangeTaxChild() {
      if (this.taxCheck != null && this.taxChildCheck != null) {
        this.urlTax = `shs_term_node_tid_depth=${this.taxChildCheck}&`;
      } else {
        this.urlTax = `shs_term_node_tid_depth=${this.taxCheck}&`;
      }
      this.page == 1;
      this.pagination_offset = 0;
      this.postCheckData();
    },
    changePage(page_num) {
      this.page = page_num;
      this.pagination_offset = `page=${this.page - 1}`;
      this.postCheckData();
    },
    async postCheckData() {
      await axios
        .post(
          `https://hr.dz72.ru/all-vacancy-api?${this.urlMO}${this.urlTax}${this.pagination_offset}`
        )
        .then((response) => {
          this.content = response.data.vacancy;

          this.page = response.data.pager.page + 1;

          this.pageCount = response.data.pager.pages;
          this.loading = false;
        })
        .catch(() => {
          this.error = true;
        });
    },
    async postMedData() {
      await axios
        .post(`https://hr.dz72.ru/api-medorg`)
        .then((response) => {
          this.medorg = response.data;
          this.onChangeMedorg();
        })
        .catch(() => {
          this.error = true;
        });
    },
    async postTermData() {
      await axios
        .post(`https://hr.dz72.ru/api-term`)
        .then((response) => {
          this.term = response.data;
          this.postTaxData();
        })
        .catch(() => {
          this.error = true;
        });
    },
    async postTaxData() {
      await axios
        .post(`https://hr.dz72.ru/api-term-child`)
        .then((response) => {
          this.term.forEach((element) => {
            if (!element.tax) {
              element["tax"] = [];
            }

            response.data.forEach((el) => {
              if (element.tid == el.tid) {
                const tax = {
                  subName: el.subName,
                  tidSub: el.tidSub,
                };
                element.tax.push(tax);
              }
            });
          });
        })
        .catch(() => {
          this.error = true;
        });
    },
    resetFilters() {
      this.medorgCheck = 79;
      this.taxCheck = null;
      this.taxChildCheck = null;
      // this.urlMO = "";
      this.urlTax = "";
      this.page == 1;
      this.pagination_offset = 0;
      this.postCheckData();
    },
  },
  mounted() {
    console.log(this.medorgCheck);
    this.postMedData();
    this.postTermData();
  },
};
</script>

<style>
#app {
  max-width: 976px;
  margin: 0 auto;
  font: 15px/25px "PT Sans Caption", Arial, sans-serif;
}
.spin {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.filter_sh h4 {
  margin-top: 0;
  margin-bottom: 6px;
  font-size: 16px;
}
.filter_sh select {
  width: 100%;
  height: 36px;
  border-radius: 6px;
  border: 1px solid #bdbdbd;
  background: none !important;
  padding: 5px 39px 5px 7px;
  color: #777777;
  cursor: pointer;
  line-height: 17px;
  position: relative;
}
.filter_sh_child {
  margin-top: 15px;
}
.filter_sh_r {
  display: flex;
  align-items: flex-end;
}
.vac_sh {
  display: flex;
  gap: 30px;
  flex-direction: column;
}
.filter_sh {
  width: 100%;
  display: flex;
  gap: 30px;
}
.card_box {
  width: 100%;
}
.card_sh {
  display: flex;
  flex-direction: row;
  margin-bottom: 30px;
  position: relative;
  box-shadow: 0 0 32px 0 rgba(26, 47, 106, 0.08);
  border-radius: 6px;
  overflow: hidden;
  padding: 15px;
  position: relative;
}
.vac_block {
  width: 70%;
}
.vac_mo_logo {
  width: 30%;
}
.vac_mo_logo img {
  width: 100%;
  max-height: 80px;
  object-fit: contain;
}
.vac_block .vac {
  margin-bottom: 8px;
  font-size: 18px;
  font-weight: 600;
  color: #646464;
}
.vac_block .vac_price {
  margin-bottom: 6px;
  font-size: 16px;
  font-weight: 600;
}
.vac_block .vac_org {
  margin-bottom: 15px;
}
.vac_block .vac_org p {
  margin: 0;
  font-size: 16px;
  font-weight: 600;
}
.vac_block .vac_work {
  margin-bottom: 25px;
}
.vac_block .vac_work span {
  text-transform: lowercase;
}
.btn-vac {
  font-size: 14px;
  line-height: 100%;
  color: #0032a5;
  border-color: #0032a5;
  position: relative;
  overflow: hidden;
  padding: 10px 15px;
  border-radius: 5px;
  border: 1px solid;
  background: none;
  outline: none;
  cursor: pointer;
  transition: all 0.5s;
  display: inline-block;
  text-decoration: none;
}
.btn-vac:before {
  content: "";
  background-color: #0032a5;
  -webkit-transform: skew(45deg, 0);
  transform: skew(45deg, 0);
  width: 0;
  height: 100%;
  position: absolute;
  top: 0px;
  left: -30px;
  z-index: -1;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}
.btn-vac:hover {
  color: #ffffff;
}
.btn-vac:hover:before {
  width: 150%;
}
.btn-vac:before {
  content: "";
  background-color: #0032a5;
  -webkit-transform: skew(45deg, 0);
  transform: skew(45deg, 0);
  width: 0;
  height: 100%;
  position: absolute;
  top: 0px;
  left: -30px;
  z-index: -1;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}

.paginate {
  list-style: none;
  padding: 0;
  display: flex;
  gap: 3px;
  user-select: none;
  flex-wrap: wrap;
}
.paginate {
  margin-top: 30px;
}
.paginate .page-item.active a,
.paginate .page-item.disabled a {
  color: #999 !important;
}
.paginate .page-item a {
  color: #000000;
  cursor: pointer;
  display: block;
  padding: 10px;
  font-size: 0.9333em;
}
.paginate li {
  display: table;
  list-style-type: none;
  position: relative;
  padding-left: 0;
  margin-bottom: 0;
}
.paginate li:before {
  content: "";
}

@media (max-width: 992px) {
  .vac_sh {
    display: flex;
    gap: 30px;
    flex-direction: column;
  }
  .filter_sh {
    width: 100%;
    display: flex;
    gap: 15px;
    align-items: flex-end;
    flex-direction: column;
  }
  .filter_sh_l {
    width: 100%;
  }
  .card_box {
    width: 100%;
  }
  .card_sh {
    flex-direction: column-reverse;
  }
  .vac_block {
    width: 100%;
  }
  .vac_mo_logo {
    width: 50%;
  }
  .vac_block .vac {
    font-size: 18px;
  }
}
.vacancy_desc {
  margin: 0 0 47px;
}
.vacancy_desc .image img {
  width: 100%;
}
.vacancy_desc .properties {
  margin: 0;
  padding: 40px 39px 25px;
  background: #f9fbfb;
  border-radius: 3px;
}
.vacancy_desc .properties .button_wrap {
  position: relative;
  z-index: 1;
}
.vacancy_desc .properties > .wrap {
  position: relative;
  top: -6px;
  padding: 0 0 0 230px;
}
.vacancy_desc .properties > .wrap.wtform {
  padding-left: 0;
}
.vacancy_desc .properties .property {
  margin: 8px 0;
}
.vacancy_desc .properties .property .title-prop {
  margin: 0 0 3px;
}
.vacancy_desc .properties .property .value {
  font-size: 1em;
  line-height: 1.4375em;
}
.vacancy_desc .detailtext {
  margin: 20px 0 0;
}
.vacancy_desc .bordered {
  border: 1px solid #ecf2f4;
}
.vacancy_desc .property.item .value {
  color: #333;
}
.vacancy_desc .property.item .title-prop {
  font-size: 0.867em;
  line-height: 1.3em;
}
@media (max-width: 600px) {
  .vacancy_desc .properties > .wrap {
    padding-left: 0;
  }
  .vacancy_desc .properties .button_wrap {
    float: none !important;
    margin: 0 0 35px;
  }
}
</style>
